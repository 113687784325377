<!-- /* eslint-disable */-->
<template>
    <v-container>
      <!-- Main Title -->
      <v-row no-gutters>
        <v-col cols="12">
          <h1>자동완성 검색 조회</h1>
        </v-col>
        <v-col cols="12" style="margin:20px 0;">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>가이드</v-expansion-panel-header>
              <v-expansion-panel-content>
                - 순위 갱신은 오전 10시 30분 이후부터 순차적으로 진행됩니다.<br>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
  
      <!-- Collapsible Description -->
      <v-row no-gutters>
        
        <v-col cols="12" style="text-align:right; margin:10px 0;">
          <v-btn style="margin-right:5px;" color="primary" dense @click="openInputDialog">
            등록
          </v-btn>
        </v-col>
      </v-row>
      <!-- Table (takes the remaining space) -->
      <v-row no-gutters class="flex-grow-1">
        <v-col cols="12" style="text-align:right; margin:10px 0;">
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="items"
            item-key="no"
            :items-per-page="9999"
            show-select
            v-model:selected="selected"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>자동완성 목록</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="searchText"
                  label="검색 키워드"
                  outlined
                  dense
                  hide-details
                  style="max-width: 250px; margin-right: 10px;"
                  @keyup.enter="getContents"
                ></v-text-field>
                <v-btn color="primary" @click="getContents" style="margin-right: 10px;">검색</v-btn>
              </v-toolbar>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <template v-for="item in items">
                  <tr :key="item.no">
                    <td rowspan="2">
                      <v-checkbox
                        v-model="selected"
                        :value="item"
                      ></v-checkbox>
                    </td>
                    <td>{{ item.keyword }}</td>
                    <td>{{ item.checkKeyword }}</td>
                    <td>{{ isNaN(parseInt(item.monthSearchCntPc)) ? item.monthSearchCntPc : parseInt(item.monthSearchCntPc).toLocaleString() }} 회</td>
                    <td>{{ isNaN(parseInt(item.monthSearchCntMobile)) ? item.monthSearchCntMobile : parseInt(item.monthSearchCntMobile).toLocaleString() }} 회</td>
                    <td>{{ item.createTime }}</td>
                    <td>
                      <v-btn style="margin-top:5px; color:#FFF; margin-right: 5px;" small color="red" @click="deletePlace(item)">삭제</v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6">
                    <div style="display: inline-block; padding: 5px; text-align:center; border-right:1px solid #ccc; width:200px;"
                      v-for="rankItem in item.rankList" v-bind:key="JSON.stringify(rankItem)">
                      {{rankItem.collect_date}}<br>
                      {{rankItem.rank}}위<br>
                      <span v-for="keyword in rankItem.keywordRankListArray" v-bind:key="keyword" style="font-size:0.9em; color:#666">
                        <span v-if="item.checkKeyword.trim() == keyword.trim()" style="font-weight:bold; background-color:gold">{{ keyword }}</span>
                        <span v-else>{{ keyword }}</span>
                        <br>
                      </span>
                    </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
  
      <v-dialog v-model="inputDialog" persistent max-width="500px">
      <div ref="report">
        <v-card>
          <v-card-title>
            <span class="text-h5">자동완성 검색 등록</span>
          </v-card-title>
  
          <v-card-text>
            <v-form>
              <!-- Input Field 1 -->
              <v-text-field label="검색어" v-model="input_keyword"></v-text-field>
  
              <!-- Input Field 2 -->
              <v-text-field label="찾을 검색어" v-model="input_check_keyword"></v-text-field>
            </v-form>
          </v-card-text>
  
          <v-card-actions style="text-align: right;">
            <!-- Button to close Dialog -->
            <v-spacer></v-spacer>
            <!-- Cancel button -->
            <v-btn color="secondary" text @click="inputDialog = false">취소</v-btn>
            <!-- Add button -->
            <v-btn color="primary" @click="addEntry()" :disabled="inputProgress">추가</v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      color="primary"
      variant="tonal"
      :timeout="2000"
    >
      {{ snackText }}
    </v-snackbar>
    </v-container>
  </template>
    
  <script>
  import { wrapFetch } from '@/config';
  
  export default {
    data() {
      return {
        snackbar: false,
        snackText: "",
        selected: [],
        headers: [
          { text: '검색 키워드', value: 'keyword' },
          { text: '찾을 키워드', value: 'checkKeyword' },
          { text: '월 조회수(PC)', value: 'monthSearchCntPc' },
          { text: '월 조회수(모바일)', value: 'monthSearchCntMobile' },
          { text: '등록일', value: 'createTime' },
          { text: '', value: 'utillity' },
        ],
        searchText: "",
        inputDialog: false,
        inputProgress: false,
        input_keyword: "",
        input_check_keyword: "",
        items: [],
      };
    },
    methods: {
      getContents(){
        let self = this;
        self.items = [];
        self.selected = [];
  
        wrapFetch(`/search/auto?searchText=${self.searchText}`, "GET")
        .then((data) => {
          data.data.map((e) => {
            e.loading = false;
            for(let i=0; i<e.rankList.length; i++){
              e.rankList[i].keywordRankListArray = e.rankList[i].keyword_rank_list.replaceAll("[", "").replaceAll("]", "").split(",");
            }
            self.items.push(e);
          })
        })
      },
      
      addEntry(){
        let self = this;
        self.inputProgress = true;
        
        wrapFetch('/search/auto', "POST", {
          keyword: self.input_keyword,
          checkKeyword: self.input_check_keyword,
        })
        .then(() => {
          self.snackText = "자동완성 검색이 완료되었습니다.";
          self.snackbar = true;
          self.inputDialog = false;
          self.inputProgress = false;
          self.getContents();
        }).catch(() => {
          self.inputProgress = false;
        })
      },
      openInputDialog(){
        this.input_keyword = "";
        this.input_place_url = "";
        this.inputDialog = true;
      },
      deletePlace(item) {
        if(confirm(`${item.keyword} 검색 기록을 정말 삭제하시겠습니까?`)){
          wrapFetch(`/search/auto?no=${item.no}`, "DELETE")
          .then(() => {
            self.snackText = "검색 기록 삭제가 완료되었습니다.";
            self.snackbar = true;
            this.getContents();
          })
        }
      },
    },
    mounted() {
      this.getContents();
    }
  };
  </script>
  <style>
  .flex-grow-1 {
    flex-grow: 1;
  }
  v-container.fill-height {
    height: 100vh;
  }
  .v-data-table__wrapper > table > tbody > tr:hover {
    background: inherit !important;
  }
  .blogCafeReviewCount {
    font-size: 0.9em;
    color: #555;
    font-weight: bold;
  }
  .visitorReviewCount {
    font-size: 0.9em;
    color: #633;
    font-weight: bold;
  }
  .saveCount {
    font-size: 0.9em;
    color: chocolate;
    font-weight: bold;
  }
  </style>